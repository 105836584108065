import './App.css'
import { Layout } from './pages/Layout'

function App() {
  return (
    <div className="App">
      <Layout/>
      {/**<AspectRatio ratio={16 / 9}>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.1332369379766!2d105.66707701547797!3d-6.504813095294697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e43b6101f7521b7%3A0xe27dc1941f8d6f30!2sSETYA%20HOMESTAY%20TANJUNG%20LESUNG!5e0!3m2!1sen!2sng!4v1640878283520!5m2!1sen!2sng'
          alt='demo'
        />
  </AspectRatio>**/}
    </div>
  );
}

export default App;
