import React from 'react'
import { Carousel } from 'react-bootstrap'

const carouselData = [
     {name: 'Beach Club Tanjung Lesung', photoLink: 'https://avalindovacation.com/wp-content/uploads/2018/04/pantai-tanjung-lesung.jpg'},
     {name: 'Pantai Pulau Peucang', photoLink: 'https://ksmtour.com/media/images/articles/Pantai-Pulau-Peucang-Banten.jpg'},
     {name: 'Snorkeling Pulau Liwungan', photoLink: 'https://getlost.id/wp-content/uploads/2021/07/@explorebantentravel.jpg'},
     {name: 'Taman Nasional Ujung Kulon', photoLink: 'https://images.immediate.co.uk/production/volatile/sites/23/2014/09/Black-rhino-calf-GettyImages-164158552-eea7319-scaled.jpg?quality=45&resize=768,574'},
]

export const CarouselComponent = () => {
     return(
          <Carousel fade>
               {
                    carouselData.map((data) => (
                         <Carousel.Item key={data.name}>
                              <img
                                   className="d-block w-100"
                                   src={data.photoLink}
                                   alt={data.name}
                              />
                              {/**<Carousel.Caption>
                                   <h3>{data.name}</h3>
                              </Carousel.Caption>**/}
                         </Carousel.Item>
                    ))
               }
          </Carousel>
     )
}