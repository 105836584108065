import React, {useRef, useState, Fragment, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { MdBathroom, MdFastfood } from "react-icons/md";
import { RiArchiveDrawerFill } from "react-icons/ri";
import { Tabs, TabList, TabPanels, Tab, TabPanel, css, Flex } from '@chakra-ui/react'
import { FaWifi, FaTv, FaHandsWash, FaPrescriptionBottle, FaPray } from "react-icons/fa";
import { Dialog, Transition } from '@headlessui/react'

const fasilitasData = [
     {
       name: 'Wifi Gratis',
       icon: FaWifi,
     },
     {
       name: 'TV Parabola / Berlangganan',
       icon: FaTv,
     },
     {
       name: 'Hand Sanitizer',
       icon: FaHandsWash,
     },
     {
       name: 'Air Mineral Gratis',
       icon: FaPrescriptionBottle,
     },
     {
       name: 'Termasuk Sarapan 2 orang',
       icon: MdFastfood
     },
     {
       name: 'Perlengkapan Mandi',
       icon: MdBathroom,
     },
     {
       name: 'Perlengkapan Sholat',
       icon: FaPray
     },
     {
       name: 'Furniture Jati dan Berkualitas',
       icon: RiArchiveDrawerFill,
     },
]

export const DetailKamar = () => {
     let {id} = useParams()
     const [open, setOpen] = useState(false)
     const [dataDetailKamar, setDataDetailKamar] = useState(null)
     const cancelButtonRef = useRef(null)

     const listDataDetailKamar = [
          {
               "id" : 3,
               "nama_tipe_kamar": "Double Bed",
               "foto": "https://i.ibb.co/xgwKJ0m/Double-Bed-Kamar-Tampak1.jpg",
               "deskripsi": "Deskripsi Kamar Double Bed",
               "harga": "300.000,00",
               "jumlah_kamar_tersedia": 2,
               "fotodetail": [
                   {
                       "id": 20,
                       "foto": "https://i.ibb.co/pvYfXbd/Double-Bed-Tampak-Depan.jpg",
                       "deskripsi_foto": "Homestay Tampak Depan",
                       "tipe_kamar": 3
                   },
                   {
                       "id": 21,
                       "foto": "https://i.ibb.co/2Pyq9Ds/Double-Bed-Kamar-Tampak2.jpg",
                       "deskripsi_foto": "Ruang Keluarga",
                       "tipe_kamar": 3
                   },
                   {
                       "id": 24,
                       "foto": "https://i.ibb.co/NmDyZY7/Double-Bed-Hand-Sanitizer.jpg",
                       "deskripsi_foto": "Hand Sanitizer",
                       "tipe_kamar": 3
                   },
                   {
                       "id": 22,
                       "foto": "https://i.ibb.co/xgwKJ0m/Double-Bed-Kamar-Tampak1.jpg",
                       "deskripsi_foto": "Kamar Tidur",
                       "tipe_kamar": 3
                   },
                   {
                       "id": 33,
                       "foto": "https://i.ibb.co/qY8mmrc/Twin-Bed-Kamar-Mandi-Tampak1.jpg",
                       "deskripsi_foto": "Kamar Mandi",
                       "tipe_kamar": 3
                   }
               ]
           },
           {
               "id" : 1,
               "nama_tipe_kamar": "Twin Bed",
               "foto": "https://i.ibb.co/WFzjJCJ/Twin-Bed-Kamar-Tampak1.jpg",
               "deskripsi": "Deskripsi Kamar Twin Bed",
               "harga": "300.000,00",
               "jumlah_kamar_tersedia": 2,
               "fotodetail": [
                   {
                       "id": 5,
                       "foto": "https://i.ibb.co/TkX8Z2M/Twin-Bed-Tampak-Depan.jpg",
                       "deskripsi_foto": "Homestay Tampak Depan",
                       "tipe_kamar": 1
                   },
                   {
                       "id": 30,
                       "foto": "https://i.ibb.co/WFzjJCJ/Twin-Bed-Kamar-Tampak1.jpg",
                       "deskripsi_foto": "Kamar Tidur Tampak 1",
                       "tipe_kamar": 1
                   },
                   {
                       "id": 31,
                       "foto": "https://i.ibb.co/QMFjdhz/Twin-Bed-Kamar-Tampak2.jpg",
                       "deskripsi_foto": "Kamar Tidur Tampak 2",
                       "tipe_kamar": 1
                   },
                   {
                       "id": 32,
                       "foto": "https://i.ibb.co/qY8mmrc/Twin-Bed-Kamar-Mandi-Tampak1.jpg",
                       "deskripsi_foto": "Kamar Mandi",
                       "tipe_kamar": 1
                   }
               ]
           },
           {
               "id" : 2,
               "nama_tipe_kamar": "Extra Double Bed",
               "foto": "https://i.ibb.co/WHGMKCD/Extra-Double-Bed-Kamar-Tampak-Dalam.jpg",
               "deskripsi": "Deskripsi Kamar Extra Double Bed",
               "harga": "500.000,00",
               "jumlah_kamar_tersedia": 4,
               "fotodetail": [
                   {
                       "id": 25,
                       "foto": "https://i.ibb.co/gvJqh6R/Extra-Double-Bed-Homestay-Tampak-Depan.jpg",
                       "deskripsi_foto": "Homestay Tampak Depan",
                       "tipe_kamar": 2
                   },
                   {
                       "id": 27,
                       "foto": "https://i.ibb.co/nPND9T8/Extra-Double-Bed-Fasilitas-Kamar.jpg",
                       "deskripsi_foto": "Fasilitas Kamar",
                       "tipe_kamar": 2
                   },
                   {
                       "id": 28,
                       "foto": "https://i.ibb.co/h2Bvvw2/Extra-Double-Bed-Kamar-Mandi.jpg",
                       "deskripsi_foto": "Kamar Mandi",
                       "tipe_kamar": 2
                   },
                   {
                       "id": 29,
                       "foto": "https://i.ibb.co/0ymwxCy/Extra-Double-Bed-Lemari.jpg",
                       "deskripsi_foto": "Lemari",
                       "tipe_kamar": 2
                   },
                   {
                       "id": 26,
                       "foto": "https://i.ibb.co/WHGMKCD/Extra-Double-Bed-Kamar-Tampak-Dalam.jpg",
                       "deskripsi_foto": "Kamar Tidur",
                       "tipe_kamar": 2
                   }
               ]
           }
     ]

     useEffect(() => {
          const getDetailKamar = async () => {
               for(var dataKamar of listDataDetailKamar){
                    if(parseInt(id) === dataKamar.id){
                         setDataDetailKamar(dataKamar)
                    }
               }
          }
          if(id && !dataDetailKamar){
               getDetailKamar()
          }
     }, [id])


     const ModalConfirmation = ({jenisKamar}) => {

          const sendMessageViaWhatsapp = () => {
               let message = `Halo Setyahomestay, Saya ingin memesan kamar ${jenisKamar}. Bagaimana caranya?`
               window.location.href = `https://wa.me/6287877714456?text=${message}`
          }

          const goToPegipegi = () => {
               window.location.href = 'https://www.pegipegi.com/hotel/pandeglang/setya_homestay_tanjung_lesung_984252/'
          }

          const goToAgoda = () => {
               window.location.href = 'https://www.agoda.com/id-id/unnamed-property/hotel/anyer-id.html'
          }

          const goToBookingCom = () => {
               window.location.href = 'https://www.booking.com/hotel/id/setya-homestay-tanjung-lesung-kabupaten-pandeglang.id.html'
          }

          return(
          <Transition.Root show={open} as={Fragment}>
               <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
               <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                    </span>
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-3 pb-4 sm:p-6 sm:pb-4">
                         <div className="sm:flex sm:items-start w-full">
                              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                   <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                                        Metode Pemesanan
                                   </Dialog.Title>
                              </div>
                         </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
                         <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-whatsapp text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={sendMessageViaWhatsapp}
                         >
                              Pesan via WhatsApp
                         </button>
                         <button
                              type="button"
                              className="w-full mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-amber-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={goToPegipegi}
                         >
                              Pesan via Pegipegi
                         </button>
                         { jenisKamar === 'Extra Double Bed' ?
                         (
                         <>
                              <button
                                   type="button"
                                   className="w-full mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                   onClick={goToAgoda}
                              >
                                   Pesan via Agoda
                              </button>
                              <button
                                   type="button"
                                   className="w-full mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-bookingcom text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                   onClick={goToBookingCom}
                              >
                                   Pesan via Booking.com
                              </button> 
                         </>) : null
                         }
                         <button
                              type="button"
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-red-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={() => setOpen(false)}
                              ref={cancelButtonRef}
                         >
                         Cancel
                         </button>
                    </div>
                    </div>
                    </Transition.Child>
               </div>
               </Dialog>
          </Transition.Root>
          )
     }

  if(!dataDetailKamar){
       return 'Loading...'
  }

  return (
    <div className="bg-white">
      <div className="pt-6">
        {/* Image gallery */}
          <Tabs variant='soft-rounded' colorScheme='blue' w="full">
               <TabList
               overflowX="auto"
               css={css({
                 scrollbarWidth: "none",
                 "::-webkit-scrollbar": { display: "none" },
                 WebkitOverflowScrolling: "touch",
                 boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                 border: "0 none"
               })}
               >
                    {
                         dataDetailKamar.fotodetail.map((dataImage) => (
                              <Tab key={dataImage.id}>{dataImage.deskripsi_foto}</Tab>
                         ))
                    }
               </TabList>
               <TabPanels>
                    {
                         dataDetailKamar.fotodetail.map((dataImage) => (
                              <TabPanel key={dataImage.id}>
                                   <Flex justifyContent={"center"}>
                                        <img style={{maxHeight: 650}} src={dataImage.foto} alt={`Foto ${dataImage.deskripsi_foto}`}/>
                                   </Flex>
                              </TabPanel>
                         ))
                    }
               </TabPanels>
          </Tabs>

        {/* dataKamar info */}
        <div className="max-w-2xl mx-auto pt-10 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:pt-16 lg:pb-24 lg:px-8 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8">
        <ModalConfirmation jenisKamar={dataDetailKamar.nama_tipe_kamar}/>
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">{dataDetailKamar.nama_tipe_kamar}</h1>
            <p className="text-center mt-4 text-base text-gray-900">{dataDetailKamar.deskripsi}</p>
          </div>

          {/* Options */}
          <div className="mt-4 lg:mt-0 lg:row-span-3">
               <p className="text-3xl my-4 text-gray-900">Rp {dataDetailKamar.harga}</p>
               {/**<span className={"px-10 py-2 mt-5 text-base font-semibold rounded-xl bg-red-600 text-white border border-indigo-500 " + (dataDetailKamar.jumlah_kamar_tersedia ? "bg-green-600" : "bg-red-600")}>
                    {
                         dataDetailKamar.jumlah_kamar_tersedia ? `Tersedia ${dataDetailKamar.jumlah_kamar_tersedia} Kamar` : `Kamar Kosong`
                    }
               </span>**/}
                    <button
                         type="submit"
                         className="mt-10 w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                         onClick={() => setOpen(true)}     
                    >
                         Pesan Kamar
                    </button>
          </div>
          <div className="py-8 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
               {/* Description and details */}
               <div className="mt-8">
                    {
                         fasilitasData.map((fasilitas) => (
                              <div className="my-10" key={fasilitas.name}>
                                   <div className="flex  md items-center justify-between">
                                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                             <fasilitas.icon className="h-6 w-6" aria-hidden="true" />
                                        </div>
                                        <h3 className="xs:text-md text-gray-900 font-medium">Size</h3>
                                        <p className="text-base text-right font-medium text-indigo-600 hover:text-indigo-500">
                                             {fasilitas.name}
                                        </p>
                                   </div>
                              </div>
                         ))
                    }
               </div>
               <div className='mt-8'>
                    <h4 className='font-bold text-2xl'>Metode Pembayaran</h4>
                    <h5 className='font-semibold text-lg'>an Darwin Simarmata</h5>
                    <div className='py-3 sm:py-5 flex flex-col text-lg'>
                         <div className='my-2 flex flex-col sm:flex-row justify-between items-center'>
                              <p className='font-semibold'>Bank BRI</p>
                              <p>090601003366504</p>
                         </div>
                         <div className='my-2 flex flex-col sm:flex-row justify-between items-center'>
                              <p className='font-semibold'>Bank Mandiri</p>
                              <p>1670000767490</p>
                         </div>
                    </div>
               </div>
          </div>
        </div>
      </div>
    </div>
  )
}