import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Tabs, TabList, TabPanels, Tab, TabPanel, css, Flex } from '@chakra-ui/react'
import { FaWifi, FaParking } from "react-icons/fa";
import {GiBarbecue, GiSecurityGate} from "react-icons/gi"
import {MdCleaningServices} from "react-icons/md"
import {SiGoogleclassroom} from "react-icons/si"
const fasilitasHomestay = [
     {name: 'Wifi', icon: FaWifi},
     {name: 'Aula', icon: SiGoogleclassroom},
     {name: 'Area Parkir Luas', icon: FaParking},
     {name: 'Cleaning Service', icon: MdCleaningServices},
     {name: 'Pos Jaga', icon: GiSecurityGate},
     {name: 'Dapur Bersama', icon: GiBarbecue},
]


export const Tentang = () => {

     // const [fotoHomestay, setFotoHomestay] = useState(null)
     // const [fotoFasilitasHomestay, setFotoFasilitasHomestay] = useState(null)

     const fotoHomestay = [
          {
               "id": 2,
               "foto": "https://i.ibb.co/RDcXWNJ/depan2.jpg",
               "deskripsi_foto": "Tampak Depan Malam"
           },
           {
               "id": 1,
               "foto": "https://i.ibb.co/ZGpLX8Z/depan1.jpg",
               "deskripsi_foto": "Tampak Depan Siang"
           }
     ]

     const fotoFasilitasHomestay = [
          {
               "id": 1,
               "foto": "https://i.ibb.co/Cv1D7QW/aula.jpg",
               "deskripsi_foto": "Aula"
           },
           {
               "id": 2,
               "foto": "https://i.ibb.co/27kMLD8/parkir.jpg",
               "deskripsi_foto": "Parkir"
           }
     ]

     // useEffect(() => {
     //      const getDetailFotoHomestay = async () => {
     //           const result = await axios.get(`http://185.210.144.99:8001/infohomestay`)
     //           setFotoHomestay(result.data)
     //      }

     //      const getDetailFotoFasilitasHomestay = async () => {
     //           const result = await axios.get(`http://185.210.144.99:8001/infohomestay/fasilitas`)
     //           setFotoFasilitasHomestay(result.data)
     //      }
          
     //      if(!fotoHomestay || !fotoFasilitasHomestay){
     //           getDetailFotoHomestay()
     //           getDetailFotoFasilitasHomestay()
     //      }

     // }, [fotoHomestay, fotoFasilitasHomestay]);
     

     if(!fotoHomestay || !fotoFasilitasHomestay){
          return 'Loading...'
     }

     return(
          <div className="bg-gray-50">
               <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                         <span className="block">Ingin berlibur di Tanjung Lesung?</span>
                         <span className="block text-indigo-600">Menginapnya di Setya Homestay.</span>
                    </h2>
                    <div className="mt-8 flex md:w-full lg:w-1/2 justify-center lg:mt-0 lg:flex-shrink-0">
                         <div className="inline-flex rounded-md shadow">
                              <a
                              href="/kamar"
                              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                              >
                              Lihat Kamar
                              </a>
                         </div>
                         <div className="ml-3 inline-flex rounded-md shadow">
                              <a
                              href="/wisata"
                              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
                              >
                              Paket Wisata
                              </a>
                         </div>
                    </div>
               </div>
               <div className="container mx-auto flex flex-col md:flex-row items-center my-2 md:my-24">
                    <div className="flex flex-col w-full lg:w-1/2 justify-center items-start pt-12 pb-10 px-6">
                         <h1 className="font-bold text-3xl my-2 text-indigo-600">Setya Homestay</h1>
                         <p className="my-4 text-justify text-lg">
                              Penginapan paling dekat dengan pintu masuk Kawasan Wisata Tanjung Lesung Banten dibandingkan dengan penginapan yang lain dan mempunyai letak strategis mudah di jangkau, aman, nyaman, dan sangat cocok sekali untuk tempat peristirahatan sementara selama berwisata di area Kawasan Wisata Tanjung Lesung.
                         </p>
                    </div>
                    <div className="w-full lg:w-1/2 lg:py-6 text-center">
                         <Tabs variant='soft-rounded' colorScheme='blue' w="full">
                         <TabList
                              overflowX="auto"
                              css={css({
                              scrollbarWidth: "none",
                              "::-webkit-scrollbar": { display: "none" },
                              WebkitOverflowScrolling: "touch",
                              boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                              border: "0 none"
                              })}
                              >
                                   {
                                        fotoHomestay.map((dataImage) => (
                                             <Tab key={dataImage.id}>{dataImage.deskripsi_foto}</Tab>
                                        ))
                                   }
                              </TabList>
                              <TabPanels>
                                   {
                                        fotoHomestay.map((dataImage) => (
                                             <TabPanel key={dataImage.id}>
                                                  <Flex justifyContent={"center"}>
                                                       <img style={{maxHeight: 650}} src={dataImage.foto} alt={`Foto ${dataImage.deskripsi_foto}`}/>
                                                  </Flex>
                                             </TabPanel>
                                        ))
                                   }
                              </TabPanels>
                         </Tabs>
                         
                    </div>
	          </div>
               <div className="max-w-7xl flex flex-column w-full mx-auto py-8 px-4 sm:px-6 lg:py-12 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                         <span className="block">Lokasi</span>
                    </h2>
                    <div className='w-full h-96 my-3'>
                         <iframe
                              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.1332369379766!2d105.66707701547797!3d-6.504813095294697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e43b6101f7521b7%3A0xe27dc1941f8d6f30!2sSETYA%20HOMESTAY%20TANJUNG%20LESUNG!5e0!3m2!1sen!2sng!4v1640878283520!5m2!1sen!2sng'
                              title='Lokasi Setya Homestay Tanjung Lesung'
                              alt='demo'
                              frameBorder={"0"} style={{border:0, width: "100%", height: "100%"}} allowFullScreen={""} aria-hidden="false" tabIndex={"0"}
                         />
                    </div>
               </div>
               <div className="container mx-auto py-8 px-4 lg:py-12 lg:px-8 lg:flex lg:flex-col lg:items-center lg:justify-between">
                    <h2 className="text-3xl my-3 font-bold tracking-tight text-gray-900 sm:text-4xl">
                         Fasilitas
                    </h2>
                    <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                         {
                              fasilitasHomestay.map((fasilitas) => (
                                   <div key={fasilitas.name} className="p-2 sm:w-1/2 w-full">
                                        <div className="bg-gray-100 rounded flex p-4 h-full justify-center items-center">
                                             <fasilitas.icon className='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'/>
                                             <span className="title-font font-medium">{fasilitas.name}</span>
                                        </div>
                                   </div>
                              ))
                         }
                    </div>
                    <div className='w-full flex flex-wrap flex-col md:flex-row justify-between'>
                         {fotoFasilitasHomestay.map((fotoFasilitas) => (
                              <img key={fotoFasilitas.id} src={fotoFasilitas.foto} className='w-full lg:w-5/12 rounded-lg mx-1 my-2' alt={'Foto ' + fotoFasilitas.deskripsi_foto}/>
                         ))}
                    </div>
               </div>
          </div>
     )
}