import React from 'react'
import { Footer } from '../components/Footer';
import { Nav } from '../components/Nav';
import { Routes } from '../Routes/Routes';

export const Layout = () => {

     return(
          <div className="min-h-full flex flex-col h-screen justify-between">
            <Nav/>
            <main>
              <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <Routes/>    
              </div>
            </main>
            <Footer/>
        </div>
     )
}