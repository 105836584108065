import React from 'react'
import {FaInstagram} from 'react-icons/fa'

export const Footer = () => {
     return(        
     
     <footer className="bg-dark pt-4 pb-4 xl:pt-8">
          <div className="max-w-screen-lg xl:max-w-screen-xl mx-auto px-4 sm:px-6 md:px-8 text-gray-400 dark:text-gray-300">
               <ul className="text-lg font-light flex flex-wrap justify-around">
                    <li className="w-1/2 md:w-1/3 lg:w-1/3">
                         <div className="text-center text-justify">
                              <h2 className="text-gray-500 text-xl uppercase mb-4">
                                   Info
                              </h2>
                              <ul>
                                   <li className="text-md mb-2 hover:text-gray-800 transition-colors duration-200">
                                   <a href="/tentang">
                                        Tentang Setyahomestay
                                   </a>
                                   </li>
                              </ul>
                         </div>
                    </li>
                    <li className="w-1/2 md:w-1/3 lg:w-1/3">
                         <div className="text-center text-justify">
                              <h2 className="text-gray-500 text-xl uppercase mb-4">
                                   Bantuan
                              </h2>
                              <ul>
                                   <li className="mb-2 hover:text-gray-800 transition-colors duration-200">
                                   <a href="/kamar">
                                        Cara Pesan
                                   </a>
                                   </li>
                              </ul>
                         </div>
                    </li>
                    <li className="w-1/2 sm:w-full md:w-1/3 lg:w-1/3">
                         <div className="text-center">
                              <h2 className="text-gray-500 text-xl uppercase mb-4">
                                   Follow Us
                              </h2>
                              <ul className='flex justify-center'>
                                   <li className="pr-2 mb-2 hover:text-gray-800 transition-colors duration-200">
                                        <a href="https://www.instagram.com/setyahomestay_tanjunglesung/">
                                             <FaInstagram/>
                                        </a>
                                   </li>
                              </ul>
                         </div>
                    </li>
               </ul>
          </div>
     </footer>

     )
}