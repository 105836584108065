import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Beranda } from '../pages/Beranda';
import { DetailKamar } from '../pages/DetailKamar';
import { Kamar } from '../pages/Kamar';
import { Tentang } from '../pages/Tentang';
export const Routes = () =>  {
     return(
          <BrowserRouter>
               <Switch>
                    <Route exact path="/">
                         <Beranda/>
                    </Route>
                    <Route exact path="/tentang">
                         <Tentang/>
                    </Route>
                    <Route exact path="/kamar">
                         <Kamar/>
                    </Route>
                    <Route exact path="/detailkamar/:id">
                         <DetailKamar/>
                    </Route>
               </Switch>
          </BrowserRouter>
     )
}