import React, {useState, useEffect,} from 'react'
// import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'

export const TipeKamar = () => {

     // const [dataTipeKamar, setDataTipeKamar] = useState(null)
     const dataTipeKamar = [
          {
               "id": 3,
               "nama_tipe_kamar": "Double Bed",
               "foto": "https://i.ibb.co/xgwKJ0m/Double-Bed-Kamar-Tampak1.jpg"
           },
           {
               "id": 1,
               "nama_tipe_kamar": "Twin Bed",
               "foto": "https://i.ibb.co/WFzjJCJ/Twin-Bed-Kamar-Tampak1.jpg"
           },
           {
               "id": 2,
               "nama_tipe_kamar": "Extra Double Bed",
               "foto": "https://i.ibb.co/WHGMKCD/Extra-Double-Bed-Kamar-Tampak-Dalam.jpg"
           }
     ]

     // useEffect(() => {
     //      const fetchDataTipeKamar = async () => {
     //           const result = await axios.get('http://185.210.144.99:8001/infokamar/ringkasan')
     //           setDataTipeKamar(result.data)
     //      }
     //      if(!dataTipeKamar){
     //           fetchDataTipeKamar()
     //      }
     // }, [dataTipeKamar])

     if(!dataTipeKamar){
          return 'Loading...'
     }

     // const ModalConfirmation = ({jenisKamar}) => {
     //      const sendMessageViaWhatsapp = () => {
     //           let message = `Halo Setyahomestay,%0a%0aSaya ingin memesan kamar dengan jenis ${jenisKamar} dengan tanggal check in ... dan checkout pada tanggal ... serta dengan jumlah tamu sebanyak ... orang. Bagaimana caranya ya? Terima kasih.`
     //           // window.location.href = `https://wa.me/628119505904?text=${message}`
     //      }

     //      const goToPegipegi = () => {
     //           window.location.href = 'https://www.pegipegi.com/hotel/pandeglang/setya_homestay_tanjung_lesung_984252/'
     //      }

     //      const goToAgoda = () => {
     //           window.location.href = 'https://www.agoda.com/tanjung-lesung-homestay/hotel/anyer-id.html'
     //      }

     //      const goToBookingCom = () => {
     //           window.location.href = 'https://www.booking.com/hotel/id/setya-homestay-tanjung-lesung-kabupaten-pandeglang.id.html'
     //      }

     //      return(
     //      <Transition.Root show={open} as={Fragment}>
     //           <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
     //           <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
     //                <Transition.Child
     //                as={Fragment}
     //                enter="ease-out duration-300"
     //                enterFrom="opacity-0"
     //                enterTo="opacity-100"
     //                leave="ease-in duration-200"
     //                leaveFrom="opacity-100"
     //                leaveTo="opacity-0"
     //                >
     //                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
     //                </Transition.Child>

     //                {/* This element is to trick the browser into centering the modal contents. */}
     //                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
     //                &#8203;
     //                </span>
     //                <Transition.Child
     //                as={Fragment}
     //                enter="ease-out duration-300"
     //                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
     //                enterTo="opacity-100 translate-y-0 sm:scale-100"
     //                leave="ease-in duration-200"
     //                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
     //                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
     //                >
     //                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
     //                <div className="bg-white px-4 pt-3 pb-4 sm:p-6 sm:pb-4">
     //                     <div className="sm:flex sm:items-start w-full">
     //                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
     //                               <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
     //                                    Metode Pemesanan
     //                               </Dialog.Title>
     //                          </div>
     //                     </div>
     //                </div>
     //                <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
     //                     <button
     //                          type="button"
     //                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-whatsapp text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
     //                          onClick={sendMessageViaWhatsapp}
     //                     >
     //                          Pesan via WhatsApp
     //                     </button>
     //                     <button
     //                          type="button"
     //                          className="w-full mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-amber-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
     //                          onClick={goToPegipegi}
     //                     >
     //                          Pesan via Pegipegi
     //                     </button>
     //                     <button
     //                          type="button"
     //                          className="w-full mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
     //                          onClick={goToAgoda}
     //                     >
     //                          Pesan via Agoda
     //                     </button>
     //                     <button
     //                          type="button"
     //                          className="w-full mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-bookingcom text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
     //                          onClick={goToBookingCom}
     //                     >
     //                          Pesan via Booking.com
     //                     </button>
     //                     <button
     //                          type="button"
     //                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-red-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
     //                          onClick={() => setOpen(false)}
     //                          ref={cancelButtonRef}
     //                     >
     //                     Cancel
     //                     </button>
     //                </div>
     //                </div>
     //                </Transition.Child>
     //           </div>
     //           </Dialog>
     //      </Transition.Root>
          
     //      )
     // }

     return(
          <div className="bg-gray-100">
               <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
               <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
                    <h2 className="text-2xl font-extrabold text-gray-900">Tipe Kamar</h2>
                    <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
                    {dataTipeKamar.map((data) => (
                    <div key={data.id} className="group relative">
                         {/**<ModalConfirmation jenisKamar={data.nama_tipe_kamar}/>**/}
                         <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                         <img
                              src={data.foto}
                              alt={'Foto ' + data.nama_tipe_kamar}
                              className="w-full h-full object-center object-cover"
                         />
                         </div>
                         <p className="mt-6 text-gray-600 font-semibold ">{data.nama_tipe_kamar}</p>
                         <div className="mt-3 sm:mt-0 sm:ml-3">
                              <a
                                   href={"/detailkamar/" + data.id}
                                   className="w-full flex items-center justify-center px-3 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                              >
                                   Detail Kamar
                              </a>
                         </div>
                    </div>
                    ))}
                    </div>
               </div>
               </div>
          </div>
     )
}