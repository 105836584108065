import React from 'react'
import { TipeKamar } from '../components/TipeKamar'


export const Kamar = () => {
     return(
          <>
               <TipeKamar/>
          </>
     )
}