import React from 'react'
import { CarouselComponent } from '../components/Carousel'

export const Beranda = () => {
     return(
          <>
          
          <div className="bg-white flex flex-col lg:flex-row xl:flex-row xxl:flex-row overflow-hidden relative">
               <div className="text-center w-full lg:w-1/2 xl:w-1/2 xxl:w-1/2 py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                    <h2 className="md:text-center text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
                         <span className="block">
                              Penginapan di Tanjung Lesung?
                         </span>
                         <span className="block mt-2 text-indigo-500">
                              Setya Homestay!
                         </span>
                    </h2>
                    <div className="md:w-full flex justify-center lg:mt-0 lg:flex-shrink-0">
                         <div className="mt-12 inline-flex rounded-md shadow">
                              <button onClick={() => window.location.href = '/tentang'} type="button" className="py-3 px-6 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                   Klik Untuk Mulai
                              </button>
                         </div>
                    </div>
               </div>
               <div className='w-full lg:w-1/2 xl:w-1/2 xxl:w-1/2'>
                    <CarouselComponent/>
               </div>
          </div>

          </>
     )
}